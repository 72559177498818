<template>
  <div>
    <v-card :height="sHeight()" :img="require('../assets/loginbackground.png')">
      <v-card-text>
        <v-row
          ><v-col><br /><br /></v-col
        ></v-row>
        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="6">
            <v-row class="login-text" align="center">
              <v-col cols="5"
                ><span>{{ $t("portalname") }}</span></v-col
              >
              <v-divider vertical thickness="3" color="#ddbd82"> </v-divider>
              <v-col cols="7" class="login-title">
                <p style="line-height: 1.4">
                  {{ $t("homemessage1") }}<br />
                  {{ $t("homemessage2") }}<br />
                  {{ $t("homemessage3") }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5"></v-col>
        </v-row>
        <v-row
          ><v-col><br /></v-col
        ></v-row>

        <v-layout row wrap align-center>
          <v-flex>
            <v-row>
              <v-col cols="3"></v-col>
              <v-col cols="6">
                <SignIn
                  v-if="needSignIn"
                  :signininfo="signininfo"
                  :register="register"
                  @sign-in="SigningIn($event)"
                  @register-demo="RegisterDemo($event)"
                  @Help="subject = 'signin'"
                />
              </v-col>
              <v-col cols="3"></v-col>
            </v-row>
            <v-row class="text-center" v-if="false">
              <v-col
                ><span
                  style="color: #ddbd82"
                  @click.stop="register = true"
                  v-if="!register"
                  >{{ $t("registerDemo") }}</span
                ><span
                  style="color: #ddbd82"
                  @click.stop="register = false"
                  v-if="register"
                  >{{ $t("dontRegister") }}</span
                ></v-col
              >
            </v-row>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SignIn from "../components/Login/SignIn";

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default {
  name: "Login",
  data() {
    return {
      signininfo: {
        username: "",
        password: "",
      },
      userinfo: {},
      needSignIn: false,
      subject: "",
      register: false,
    };
  },
  components: {
    SignIn,
  },
  methods: {
    sHeight() {
      return (screen.height - 100).toFixed() + "px";
    },
    // async SigningIn(ev) {
    //   if (this.signininfo.username && this.signininfo.password) {
    //      const ret = await fetch("backend/staffsignin/", {
    //       method: "POST",
    //       cache: "no-cache",
    //       headers: {
    //         "content-type": "application/json",
    //       },
    //       body: JSON.stringify(this.signininfo),
    //     })
    //       .then((response) =>
    //         response.json()
    //       )
    //       .then((data) => {
    //         if (data.loginName == this.signininfo.username) {
    //           this.userinfo = data;
    //           //              console.log("userinfo", this.userinfo);
    //           this.$emit("signed-in", this.userinfo);
    //         }
    //       })
    //       .catch((error) => {
    //         console,log('ret', ret)
    //         console.error("error", error);
    //         var err = ret.status
    //         alert(this.$t("login.error_" + err) + " (" + err + ")");
    //       });
    //       console.log(ret)
    //   } else {
    //     alert(this.$t("login.incomplete"));
    //   }
    // },
    async SigningIn(ev) {
      if (this.signininfo.username && this.signininfo.password) {
        const res = await fetch("backend/staffsignin/", {
          method: "POST",
          cache: "no-cache",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(this.signininfo),
        });
        if (res.status === 200) {
          this.userinfo = await res.json();
          this.$emit("signed-in", this.userinfo);
        } else if (res.status === 201) {
          alert(this.$t("login.need_verification"));
        } else {
          alert(this.$t("login.error_" + res.status) + " (" + res.status + ")");
        }
      } else {
        alert(this.$t("login.incomplete"));
      }
    },
    async RegsiterDemo(ev) {
      if (this.signininfo.username && this.signininfo.password) {
        const res = await fetch("backend/registerdemo/", {
          method: "PUT",
          cache: "no-cache",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(this.signininfo),
        });
        if (res.status === 200) {
          this.userinfo = await res.json();
          this.$emit("signed-in", this.userinfo);
        } else if (res.status === 201) {
          alert(this.$t("login.need_verification"));
        } else {
          alert(this.$t("login.error_" + res.status) + " (" + res.status + ")");
        }
      } else {
        alert(this.$t("login.incomplete"));
      }
    },
    async AutoSignIn(sessid) {
      await fetch("backend/staffsignin/?" + sessid, {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.loginName) {
            this.userinfo = data;
            this.$emit("signed-in", this.userinfo);
            const lang = getCookie("language");
            if (lang) {
              this.$i18n.locale = lang;
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.needSignIn = true;
        });
    },
    // async AutoSignIn(sessid){
    //     const res = await fetch("backend/staffsignin/?"+sessid, {
    //       method: "GET",
    //       headers: {
    //         "content-type": "application/json",
    //       },
    //     });
    //     if (res.status === 200) {
    //       this.userinfo = await res.json()
    //       this.$emit('signed-in', this.userinfo)
    //       const lang = getCookie("language")
    //       if (lang){
    //         this.$i18n.locale = lang
    //       }
    //     } else {
    //       this.needSignIn = true
    //     }

    // }
  },
  created() {
    const sessid = getCookie("session");
    if (sessid) {
      this.AutoSignIn(sessid);
    } else {
      this.needSignIn = true;
    }
  },
};
</script>
